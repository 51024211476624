const appConfig = {
    // apiPrefix: 'http://localhost:4000/api/',
    apiPrefix: 'https://jivhala-api.5techg.com/api/',
    authenticatedEntryPath: '/dashboard',
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/',
    locale: 'en',
    enableMock: true,
}

export default appConfig
